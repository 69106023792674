import { html, css } from 'lit'
import { TableBase } from 'shared/tables/table-base.component'
import { graphQL } from "shared/graphQL"
import { Assets } from 'shared/assets'
import { Dialog } from 'shared/dialog-box.component'
import 'shared/tables/table-filter-user-employee.component'


class UsersTable extends TableBase {

  static styles = [
    TableBase.styles,
    css`
    .table-base {
      background-color: #FFFFFF;
    }


    .c-column[data-type=icon] img {
      width: 36px;
    }
    .c-column[data-type=inheriting] {
      max-width: 129px;
    }
    .c-column[data-type=issue_flags] {
      max-width: 90px;
    }
    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0
    }
    .action:hover svg circle {
      stroke: #d6d9e0
    }

    a.c-column[data-type=fullName] {
      flex-flow: wrap;
    }

    .c-column[data-type="imageUrl"] {
      flex: none;
      width: 54px !important;
      min-width: 54px !important;
      max-width: 54px !important;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 12px;
      padding-bottom: 12px;
      align-items: unset;
    }

    .c-column .user-in-content_badge {
        position: absolute;
        right: 6px;
        bottom: 10px;
        color: #6d6d6d;
        font-size: 10px;
        text-align: center;
        line-height: 14px;
        width: 16px;
        height: 14px;
        box-shadow: 1px 1px 1px 0px #6d6d6d;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
    }

    .circular-img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
        display: block;
        background-color: rgb(91, 91, 91);
    }

    .user_name{
      font-weight: bold;
      top: -1px;
    }
    .user_position{
      font-size: 12px;
      top: 21px;
    }
    .flex-container{
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      width: -webkit-fill-available
    }
    .flex-container span{
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .far{
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
    }
    .fa-eye:before {
      content: "\\f06e";
    }

    .attribute_pill{
      border: 1px solid #979797;
      border-radius: 10px;
      font-size: 15px;
      text-align: center;
      white-space: nowrap;
      padding: 0 8px 0 8px;
      margin-right: 5px;
      height: 24px;
      min-width: fit-content;
    }

    `,
  ];

  static properties = {
    orgid: {},
    accountid: {},
    locationid: {},
    locationadmin: {},
    missingImageUrl: {},
    attrid: {},
    allowed: {}
  }

  constructor() {
    super()
    this.route = { item: this.locationid ? '/employees/:id' : 'employees/:id' };
    this.showDetails = GraphQL.accountId;

    this.dataName = 'Users'
    this.columns = [
      { key: 'bulk_action', title: '', display: 'always-left' },
      { key: 'imageUrl', title: 'Profile Photo', circular_image: true, hide_in_column: true },
      { key: 'uniqueIdentifier', title: 'Unique Id', sortable: 'orderByCustomerUid' }, // TODO: missing 'orderBy'
      { key: 'fullName', title: 'Name', sortable: 'orderByFullName' },
      { key: 'firstName', title: 'First name', sortable: 'orderByFirstName' }, // TODO: missing 'orderBy'
      { key: 'lastName', title: 'Last name', sortable: 'orderByLastName' }, // TODO: missing 'orderBy'
      { key: 'preferredName', title: 'Preferred name', sortable: 'orderByPreferredName' }, // TODO: missing 'orderBy'
      { key: 'assignment', title: 'Assignment' },
      { key: 'email', title: 'Email', sortable: 'orderByEmail' },
      { key: 'attributes', title: 'Attributes' },
      { key: 'organization', title: 'Organization', sortable: 'orderByOrganization' }, // TODO: missing 'orderBy'
      { key: 'account', title: 'Account', sortable: 'orderByAccount' }, // TODO: missing 'orderBy'
      { key: 'militaryService', title: 'Military service', sortable: 'orderByMilitaryService' },
      { key: 'startDate', title: 'Start date', sortable: 'orderByWorkStart' },
      { key: 'birthday', title: 'Birthday', sortable: 'orderByBirthday' },
      { key: 'birthMonth', title: 'Birth month', sortable: 'orderByBirthMonth' }, // TODO: missing 'orderBy'
      { key: 'birthDayOfMonth', title: 'Birth day', sortable: 'orderByBirthDay' }, // TODO: missing 'orderBy'
      { key: 'role', title: 'Role', sortable: 'orderByRole' },
      { key: 'actions', title: '', display: 'always-right' }
    ];

    this.orderBy = 'orderByFullName'; // default
    this.orderType = 'ascending'; // default

    this.paginationEnabled = true;
    this.paginationOptions.dataName = this.dataName;
    this.searchEnabled = true;
    this.settingsEnabled = true;
  }

  generateBulkActionMenu() {
    const allowed = new Set(
      (this.allowed || '')
        .split(',')
        .map(x => x.toLowerCase())
    );
    if (this.orgid == '') { // system
      if (allowed.has('destroy'))
        return this.bulkActionsMenu = html`
                <div class="action delete" @click="${this._delete}">
                    ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
                </div>`;
    } else {
      if (allowed.has('edit') && allowed.has('destroy') && (this.accountid))
        return this.bulkActionsMenu = html`
                <div class="action" @click="${this._edit_attributes}">
                    ${Assets.inlineSvg('subnavigation/attributes.svg')} Edit Attributes
                </div>
                <div class="action delete" @click="${this._delete}">
                    ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
                </div>
            `;
      else if (allowed.has('edit') && (this.accountid))
        return this.bulkActionsMenu = html`
              <div class="action" @click="${this._edit_attributes}">
                  ${Assets.inlineSvg('subnavigation/attributes.svg')} Edit Attributes
              </div>
          `;
      else if (allowed.has('destroy'))
        return this.bulkActionsMenu = html`
                <div class="action delete" @click="${this._delete}">
                    ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
                </div>
            `;
    }
  }

  connectedCallback() {
    super.connectedCallback();

    if (GraphQL.accountId) {
      this.filterSections = html`
            <table-filter-groups></table-filter-groups>
            <table-filter-attributes attrId=${this.attrid}></table-filter-attributes>
            <table-filter-roles></table-filter-roles>
            <table-filter-user-employee></table-filter-user-employee>`;
    }
  }

  fetchColumns() {

  }

  fetchCacheName() {
    if (this.accountid) {
      return "account_" + this.name
    } else if (this.orgid) {
      return "org_" + this.name
    } else if (this.locationid) {
      return "location_" + this.name
    } else {
      return this.name
    }
  }

  findIds() {
    let ids = [];
    let users = this.shadowRoot.querySelectorAll('mss-checkbox');
    for (const user of users) {
      if (user.checked) {
        ids.push(user.value);
      }
    }
    return ids;
  }

  _edit_attributes(e) {
    let ids = this.findIds();
    window.location.href = Routes.edit_attributes_employees_path({ ids: ids })
  }

  _delete(e) {
    let ids
    if (e.data && e.data.thiz && e.data.userId) {
      ids = [e.data.userId]
      Dialog.open(`
                <div slot="message">Are you sure you want to delete this record?</div>
                <mss-button slot="button" class="primary" value="Yes">Yes</mss-button>
                <mss-button slot="button" class="secondary" value="No">No</mss-button>
            `, (value) => {
        if (value === 'Yes') {
          $.ajax({
            type: 'DELETE',
            url: 'employees',
            data: `id[]=${ids}`,
            success: null
          });
        } else {
          return;
        }
      })
    } else {
      ids = this.findIds().join('&id[]=')
      $.ajax({
        type: 'DELETE',
        url: 'employees',
        data: `id[]=${ids}`,
        success: null
      });
    }
  }

  errorHandler(errors) {
    console.log('Users table errors: ', errors);
  }

  getData() {
    let query = `
            query users($filters: UserFilter!, $total_count_filters: UserFilter!) {
                userCount(filter: $total_count_filters)
                users(filter: $filters) {
                    id
                    fullName
                    firstName
                    lastName
                    preferredName
                    email
                    customAttributes{
                      name
                    }
                    militaryService
                    startDate
                    position
                    birthday
                    contents{
                      id
                    }
                    profileImage{
                      small
                    }
                    birthMonth
                    locations{
                      name
                    }
                    birthDayOfMonth
                    uniqueIdentifier:customerUid
                    organization {
                        id
                        name
                    }
                    account {
                        id
                        name
                    }
                    role {
                        name
                        description
                    }
                }
            }
        `;

    let variables = {
      filters: {
        pager: {
          pageSize: this.pageSize,
          page: this.page - 1,
        },
      },
      total_count_filters: {}
    };

    if (this.orgid > 0) {
      variables.filters['forOrganizationId'] = this.orgid;
      variables.total_count_filters['forOrganizationId'] = this.orgid;
    }
    if (this.accountid > 0) {
      variables.filters['forAccountId'] = this.accountid;
      variables.total_count_filters['forAccountId'] = this.accountid;
    }
    if (this.locationadmin != undefined && this.locationadmin == 'true') {
      let locations = JSON.parse(this.locationid)
      variables.filters['forLocation'] = locations;
      variables.total_count_filters['forLocation'] = locations;
      // delete variables.filters['forAccountId']
      // delete variables.filters['forOrganizationId']
      // delete variables.total_count_filters['forAccountId']
      // delete variables.total_count_filters['forOrganizationId']
    }

    if (this.orderBy && this.orderType) {
      variables.filters[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
      variables.total_count_filters[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
    }
    if (this.searchValue) {
      variables.filters['textSearch'] = this.searchValue
      variables.total_count_filters['textSearch'] = this.searchValue
    }

    let values = {};
    if (this.filterValues) {
      if ((values = this.filterValues["table-filter-groups"])) {
        variables.filters['forGroups'] = values;
        variables.total_count_filters['forGroups'] = values;
      }
      if ((values = this.filterValues["table-filter-attributes"])) {
        variables.filters['forCustomAttribute'] = values;
        variables.total_count_filters['forCustomAttribute'] = values;
      }
      if ((values = this.filterValues["table-filter-roles"])) {
        variables.filters['forRoles'] = values;
        variables.total_count_filters['forRoles'] = values;
      }
      if ((values = this.filterValues["table-filter-user-employee"])) {
        if (!(values['employeesOnly'] == 'true' && values['usersOnly'] == 'true')) {
          variables.filters['usersOnly'] = values['usersOnly'] === 'true';
          variables.total_count_filters['usersOnly'] = values['usersOnly'] === 'true';
          variables.filters['employeesOnly'] = values['employeesOnly'] === 'true';
          variables.total_count_filters['employeesOnly'] = values['employeesOnly'] === 'true';
        }
      }
    } else if (this.attrid) {
      variables.filters['forCustomAttribute'] = [this.attrid];
      variables.total_count_filters['forCustomAttribute'] = [this.attrid];
    }

    graphQL.query({
      query: query,
      variables: variables,
      success: (response) => {
        if (response.errors) {
          this.data = []
          errorHandler(response.errors)
        }
        this.totalCount = response.data.userCount;
        this.data = response.data.users;
        this.route = { item: this.locationid ? '/employees/:id' : 'employees/:id' };
      },
      error: (message) => {
        this.errorHandler(message)
        this.data = []
      },
    });
  }

  afterUpdated() {
    //   this.initializeCircularImg();
  }


  // formatCircularImg(img, width) {
  //     if (img.width() > img.height()) {
  //         // Landscape
  //         img.css('width', '60px');
  //         img.css('height', '60px ');
  //         img.css('margin-left', `-${(img.width() - width) / 2.0}px`);
  //     } else {
  //         // Portrait
  //         img.css('width', '60px');
  //         img.css('height', '60px');
  //         img.css('margin-left', '0');
  //     }
  // }

  // initializeCircularImg() {
  //     let thiz = this
  //     $(this.shadowRoot).find('.circular-img').each(function () {
  //         let div = $(this);
  //         let img = div.find('img');
  //         let domImg = img[0];
  //         let isLoaded = domImg.complete && domImg.naturalHeight !== 0;
  //         if (isLoaded) {
  //             // thiz.formatCircularImg(img, div.width());
  //             thiz.formatCircularImg(img, 32);
  //         } else {
  //             img.on('load', function () {
  //                 // thiz.formatCircularImg($(this), div.width());
  //                 thiz.formatCircularImg($(this), 32);
  //             });
  //         }
  //     });
  // }

  cellUrl(key, item) {
    if (key != 'actions') {
      let path;

      switch (window.location.pathname) {
        case '/system/employees':
          path = Routes.system_employee_path(item.id);
          break;
        case '/organization/employees':
          path = Routes.organization_employee_path(item.id);
          break;
        default:
          path = Routes.employee_path(item.id)
      }

      return path
    }
  }

  cellValue(colKey, item) {
    const allowedActions = new Set(
      (this.allowed || '')
        .split(',')
        .map(x => x.toLowerCase())
    );
    let value = html``;
    let baseUrl = this.locationid ? '/employees/' : 'employees/'
    let baseUserUrl = this.locationid ? '/users/' : 'users/'

    switch (colKey) {
      case 'bulk_action':
        if (!allowedActions.has('edit')) {
          value = html``
        } else {
          value = html`
            <mss-checkbox name="User" value="${item.id}"></mss-checkbox>`
        }
        break;

      case 'uniqueIdentifier':
        value = item?.uniqueIdentifier;
        break;

      case 'imageUrl':
        let src = item.profileImage?.small || this.missingImageUrl
        value = html`<img class="circular-img" src=${src}>`
        break;

      case 'fullName':
        value = item?.position ? [item?.fullName, item?.position] : item?.fullName;
        break;

      case 'firstName':
        value = item?.firstName;
        break;

      case 'lastName':
        value = item?.lastName;
        break;

      case 'preferredName':
        value = item?.preferredName;
        break;

      case 'attributes':
        value = item.customAttributes.map(x => x.name)
        break;

      case 'email':
        value = item?.email;
        break;

      case 'militaryService':
        value = item?.militaryService;
        break;

      case 'startDate':
        value = item?.startDate;
        break;

      case 'birthday':
        value = item?.birthday;
        break;

      case 'birthMonth':
        value = item?.birthMonth;
        break;

      case 'birthDayOfMonth':
        value = item?.birthDayOfMonth;
        break;

      case 'assignment':
        value = item.locations.map(x => x.name).join(', ')
        break;
      case 'organization':
        value = item?.organization?.name;
        break;

      case 'account':
        value = item?.account?.name;
        break;

      case 'role':
        if (item && item.role) {
          value = item.role.name
        } else {
          value = 'Employee'
        }
        break;

      case 'actions':
        value = [
          {
            title: 'Edit',
            url: `${baseUrl}${item.id}/edit`,
            httpMethod: 'GET',
            remote: false,
            protect: 'edit'
          },
          {
            title: 'Delete',
            url: `${baseUrl}${item.id}`,
            httpMethod: 'DELETE',
            remote: false,
            confirm: `Are you sure you want to delete ${item.name || 'this record'}?`,
            protect: 'destroy'
          },
        ];

        if (this.showDetails) {
          value = [{
            title: 'View',
            url: `${baseUrl}${item.id}`,
            httpMethod: 'GET',
            remote: false,
            protect: 'show'
          }, ...value];
        }

        const allowed = new Set(
          (this.allowed || '')
            .split(',')
            .map(x => x.toLowerCase())
        );

        if (allowed.has('edit') && item.role != null) {
          value.push({
            title: 'Impersonate',
            url: `${baseUserUrl}${item.id}/impersonate`,
            httpMethod: 'POST',
            remote: false,
            protect: 'edit'
          });
        }
    }
    return value;
  }

  cellClick(key, item, event) {
    if (key !== 'bulk_action' && key !== 'favorite' && key !== 'actions') {
      //window.location.href = Routes.player_path(item.id)
    }
  }

  defaultTableSettings() {
    let data = {
      desktop: {
        visibleColumns: [
          'imageUrl',
          'fullName',
          'assignment',
          'email',
          'role'
        ]
      },
      tablet: {
        visibleColumns: [
          'imageUrl',
          'fullName',
          'assignment',
          'email',
          'role',
        ]
      },
      mobile: {
        visibleColumns: [
          'imageUrl',
          'fullName',
          'assignment'
        ]
      },
    }
    if (this.accountid || this.locationid) {
      data.desktop.visibleColumns.filter(c => c !== 'account');
      data.tablet.visibleColumns.filter(c => c !== 'account');
      data.mobile.visibleColumns.filter(c => c !== 'account');
    }
    if (this.orgid || this.locationid) {
      data.desktop.visibleColumns.filter(c => c !== 'organization');
      data.tablet.visibleColumns.filter(c => c !== 'organization');
      data.mobile.visibleColumns.filter(c => c !== 'organization');

    }
    return data
  }

  cColumn() {
    if (this.showDetails) {
      return html`<a class="c-column ${classes}" data-type="${col.key}" href="${cellUrl}">${link}</a>`
    } else {
      return html`<a class="c-column ${classes}" data-type="${col.key}" href="${cellUrl}">${link}</a>`
    }
  }
}

customElements.define('users-table', UsersTable)
